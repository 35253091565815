// ================================================================================================
// 	File Name: invoice.scss
// 	Description: Invoice Layout page layouts SCSS.
// 	----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 4 Admin Dashboard Template
//    Version: 3.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

#invoice-template{
	padding:4rem;
}
