/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/dist/css/bootstrap.css";

body{
    background-color: #f7f7f7 !important;
}

.margin-top-70{
    margin-top: 70px;
}*/
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

:host ::ng-deep .email-fixed-header {
  min-height: calc(100% - 207px)!important;
  margin-top: 0!important;
  overflow-y: hidden !important;
}

@font-face {
  font-family: 'ificon';
  src:  url('assets/fonts/ificon/fonts/ificon.eot?e183ri');
  src:  url('assets/fonts/ificon/fonts//ificon.eot?e183ri#iefix') format('embedded-opentype'),
        url('assets/fonts/ificon/fonts//ificon.ttf?e183ri') format('truetype'),
        url('assets/fonts/ificon/fonts//ificon.woff?e183ri') format('woff'),
        url('assets/fonts/ificon/fonts//ificon.svg?e183ri#ificon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ificon {
  font-family: 'ificon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fall-accident-icon:before {
  content: "\e900";
}


/* Googleライクなツールチップ */

.google-like-tooltip-trigger .google-like-tooltip {
  display: none;
}
.google-like-tooltip-trigger:hover .wifi-icon-wrapper {
  z-index: 3002;  /* moveableのz-indexが3000 */
}
.google-like-tooltip-trigger:hover .google-like-tooltip {
  display: block;
  z-index: 3001;  /* moveableのz-indexが3000 */
}
.google-like-tooltip {
  position: relative;
  padding: 7px 10px;
  background: #FFF;
  border: solid 0px lightgrey;
  border-radius: 1rem;
  box-shadow: 0 0 10px lightgrey, 0 5px 5px lightgrey;
  font-size: 0.95rem;
  color: #6b6f82;
}
.google-like-tooltip:before {
  content: "";
  position: absolute;
  bottom: -22px;
  left: 50%;
  margin-left: -15px;
  border: 12px solid transparent;
  border-top: 12px solid #FFF;
  z-index: 2;
}
.google-like-tooltip:after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -17px;
  border: 14px solid transparent;
  border-top: 14px solid lightgrey;
  z-index: 1;
}
.google-like-tooltip td {
  padding: 0rem 0.5rem;
}
