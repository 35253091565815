// ================================================================================================
// 	File Name: search.scss
// 	Description: Page content search pages page layouts SCSS.
// 	----------------------------------------------------------------------------------------------
// Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 4 Admin Dashboard Template
//	Version: 3.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core variables and mixins overrides
@import "../bootstrap/functions";

@import "../core/variables/bootstrap-variables";
@import "../bootstrap/variables";

@import "../core/variables/components-variables";

//Search Navbar
#search-nav{
    .nav{
        border-bottom:2px solid #e0e0e0;
    }
    .nav-link{
        &.active{
            padding-bottom:9px;
            border-bottom: 2px solid $info;
            margin-bottom: -2px;
        }
    }
}

#search-results{
    .media-search{
        padding-top:5px;
    }
}
