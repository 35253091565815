// ===============================================================================================
// 	File Name: error.scss
// 	Description: Page content different user error page layouts SCSS.
// 	----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 4 Admin Dashboard Template
//    Version: 3.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.error-code {
	font-size: 9rem;
}
